@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Ubuntu&display=swap');

.loading_spainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.8);
}

.loading_spainer p {
    color: #fff;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1em;
}

.card_main {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 33%;
    height: 30%;
    padding: 1.5rem 1rem;
    border-radius: 25px;
    opacity: .8;
}

.quote_text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.quote_text p {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.892);
    margin: 0px;
}

.quote_author {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.quote_author p {
    font-family: 'Lobster', cursive;
    color: #000000;
    font-size: 1.4em;
}

.new_quote {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.socisl_button button {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    /* border: 1px solid black; */
    border: none;
    border-radius: 50%;
}

.socisl_button button:nth-child(1) {
    background: #00acee;
    color: white;
}

.socisl_button button:nth-child(1):hover {
    background: white;
    color: #00acee;
    border: 1px solid #00acee;
    transition: .6s;
}

.socisl_button button:nth-child(2) {
    background: #25D366;
    color: white;
    margin-left: 1rem;
}

.socisl_button button:nth-child(2):hover {
    background-color: white;
    color: #25D366;
    border: 1px solid #25D366;
    transition: .6s;
}

.quote_button button {
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    background: #ffbe76;
    border-radius: 15px;
    padding: .5em 1em;
    font-weight: 600;
    font-size: 1em;
    color: #222f3e;
    border: 3px solid #ffbe76;
    border-radius: 10px;
}

.quote_button button:hover {
    background: #f0932b;
    transition: .6s;
}

@media screen and (max-width: 480px) {
    .card_main {
        width: 84%;
    }

    .quote_text p {
        font-size: 1em;
    }

    .quote_author p {
        font-size: 1.1em;
    }
}

@media screen and (max-width: 820px) {
    .card_main {
        width: 80%;
        height: 23%;
    }
}

;

@media screen and (max-width: 1024px) {
    .card_main {
        width: 50%;
        height: 27%;
    }
}

;